import React, {useEffect, useRef} from 'react';
import Typed from 'typed.js';

const Home = () => {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['Software engineer', 'Solution architect', 'Devops engineer'],
            typeSpeed: 100,
            loop: true,
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);
    return(
        <>
            <section id="hero" className="d-flex flex-column justify-content-center">
                <div className="container" data-aos="zoom-in" data-aos-delay="100">
                    <h1>Elhousseyn arab</h1>
                    <p>I'm <span ref={el}></span></p>
                    <div className="social-links">
                        <a href="https://twitter.com/hocinearb" target="_blank" className="twitter"><i className="bx bxl-twitter"></i></a>
                        <a href="https://www.linkedin.com/in/elhousseyn-arab/" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        <a href="https://github.com/arabelhousseyn" target='_blank' className="github"><i className="bx bxl-github"></i></a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;